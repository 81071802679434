import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import axios from "../../axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../components/Loader";

function FranchiseeEdit() {
    const { id} = useParams();
    const [item, setItem] = useState(null);
    const [editedItem, setEditedItem] = useState(null);
    const [errors, setErrors] = useState({});
    const [countries, setCountries] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`/get_franchisee/${id}`);
                // console.log('Response', response.data.data);
                setItem(response.data.data);
                setEditedItem(response.data.data);

                const countriesList = await axios.get('/country_list');
                setCountries(countriesList.data.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [id]);

    const handleInputChange = (fieldName, value) => {
        setEditedItem((prevItem) => ({
            ...prevItem,
            [fieldName]: value,
        }));

        setErrors((prevErrors) => ({
            ...prevErrors,
            [fieldName]: ""
        }));
    }

    const validateForm = () => {
        const errors = {};

        if (!editedItem.first_name.trim()) {
            errors.first_name = 'This field is required';
        }
        if (!editedItem.last_name.trim()) {
            errors.last_name = 'This field is required';
        }

        if (!editedItem.email.trim()) {
            errors.email = 'This field is required';
        } else if (!/^\S+@\S+\.\S+$/.test(editedItem.email)) {
            errors.email = 'Invalid email format';
        }

        if (!editedItem.phone_no.trim()) {
            errors.phone_no = 'This field is required';
        }

        if (!editedItem.company_name.trim()) {
            errors.company_name = 'This field is required';
        }

        if (!editedItem.your_position.trim()) {
            errors.your_position = 'This field is required';
        }

        if (!editedItem.country_of_interest.trim()) {
            errors.country_of_interest = 'This field is required';
        }

        if (!editedItem.corporate_request.trim()) {
            errors.corporate_request = 'This field is required';
        }

        if (!editedItem.main_interest.trim()) {
            errors.main_interest = 'This field is required';
        }

        if (!editedItem.main_profile_group.trim()) {
            errors.main_profile_group = 'This field is required';
        }

        if (editedItem.main_profile_group === "Other" && !editedItem.others) {
            errors.others = 'This field is required';
        }

        if (!editedItem.current_liquidity_asset.trim()) {
            errors.current_liquidity_asset = 'This field is required';
        }

        if (!editedItem.capital_to_invest.trim()) {
            errors.capital_to_invest = 'This field is required';
        }

        if (!editedItem.experience_in_retail.trim()) {
            errors.experience_in_retail = 'This field is required';
        }

        if (!editedItem.experience_in_fb_sector.trim()) {
            errors.experience_in_fb_sector = 'This field is required';
        }

        if (!editedItem.company_experience_in_franchisee.trim()) {
            errors.company_experience_in_franchisee = 'This field is required';
        }

        if (!editedItem.plan_to_extend.trim()) {
            errors.plan_to_extend = 'This field is required';
        }

        setErrors(errors);
        return Object.keys(errors).length === 0; // Form is valid if there are no errors
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submission

        try {
            if (validateForm()) {
                setLoading(true);
                // You can make an API call here to update the data
                await axios.post(`update_franchisee/${id}`, editedItem);
                // Optionally, you can fetch the updated data again and update the state
                const response = await axios.get(`get_franchisee/${id}`);
                setItem(response.data.data);

                toast.success(response.data.message);
                navigate(`/franchisee/${id}`);
            }
        } catch (error) {
            console.error('Error updating data:', error);
        } finally {
            setLoading(false);
        }
    };

    if (!item) {
        return <Loader />;
    }



    return(
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">Profile</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><Link to="/franchisee">Franchisee</Link></li>
                                <li className="breadcrumb-item active"><Link to={`/franchisee/${id}`}>{item.first_name}</Link></li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <form onSubmit={handleFormSubmit} className="display-edit">
                <div className="row">
                    <div className="col-xl-4">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title mb-4">Personal Information</h4>
                                <div className="table-responsive">
                                    <table className="table mb-0">
                                        <tbody>
                                        <tr>
                                            <th scope="row">First Name <span className="text-danger">*</span></th>
                                            <td>
                                                <div className="form-group">
                                                    <input type="text"
                                                           className="form-control"
                                                           value={editedItem.first_name}
                                                           onChange={(e) => handleInputChange("first_name", e.target.value)}
                                                    />
                                                    {errors.first_name && (
                                                        <div className="tool_tip text-danger">{errors.first_name}</div>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Last Name <span className="text-danger">*</span></th>
                                            <td>
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={editedItem.last_name}
                                                        onChange={(e) => handleInputChange("last_name", e.target.value)}
                                                    />
                                                    {errors.last_name && (
                                                        <div className="tool_tip text-danger">{errors.last_name}</div>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Mobile <span className="text-danger">*</span></th>
                                            <td>
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={editedItem.phone_no}
                                                        onChange={(e) => handleInputChange("phone_no", e.target.value)}
                                                    />
                                                    {errors.phone_no && (
                                                        <div className="tool_tip text-danger">{errors.phone_no}</div>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">E-mail <span className="text-danger">*</span></th>
                                            <td>
                                                <div className="form-group">
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        value={editedItem.email}
                                                        onChange={(e) => handleInputChange("email", e.target.value)}
                                                    />
                                                    {errors.email && (
                                                        <div className="tool_tip text-danger">{errors.email}</div>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Company Name/Website <span className="text-danger">*</span></th>
                                            <td>
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={editedItem.company_name}
                                                        onChange={(e) => handleInputChange("company_name", e.target.value)}
                                                    />
                                                    {errors.company_name && (
                                                        <div className="tool_tip text-danger">{errors.company_name}</div>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-8">
                        <div className="card">
                            <div className="card-body">
                                <div className="tab-pane fade active show" id="v-pills-gen-ques" role="tabpanel" aria-labelledby="v-pills-gen-ques-tab">
                                    <h4 className="card-title mb-5">Company Information</h4>
                                    <div className="faq-box d-flex mb-4">
                                        <div className="flex-shrink-0 me-3 faq-icon">
                                            <i className="bx bx-help-circle font-size-20 text-success"></i>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="font-size-15">Your position in the company <span className="text-danger">*</span></h5>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={editedItem.your_position}
                                                    onChange={(e) => handleInputChange("your_position", e.target.value)}
                                                />
                                                {errors.your_position && (
                                                    <div className="tool_tip text-danger">{errors.your_position}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="faq-box d-flex mb-4">
                                        <div className="flex-shrink-0 me-3 faq-icon">
                                            <i className="bx bx-help-circle font-size-20 text-success"></i>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="font-size-15">Country Of interest <span className="text-danger">*</span></h5>
                                            <div className="form-group">
                                                <select
                                                    className="form-control"
                                                    value={editedItem.country_of_interest}
                                                    onChange={(e) => handleInputChange("country_of_interest", e.target.value)}
                                                >
                                                    <option value="" disabled>Select Country</option>
                                                    {countries.map((country) => (
                                                        <option key={country} value={country}>
                                                            {country}
                                                        </option>
                                                    ))}
                                                </select>
                                                {errors.country_of_interest && (
                                                    <div className="tool_tip text-danger">{errors.country_of_interest}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="faq-box d-flex mb-4">
                                        <div className="flex-shrink-0 me-3 faq-icon">
                                            <i className="bx bx-help-circle font-size-20 text-success"></i>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="font-size-15">Please, indicate if you are interested in other countries/regions</h5>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={editedItem.interest_in_other_countries}
                                                    onChange={(e) => handleInputChange("interest_in_other_countries", e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="faq-box d-flex mb-4">
                                        <div className="flex-shrink-0 me-3 faq-icon">
                                            <i className="bx bx-help-circle font-size-20 text-success"></i>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="font-size-15">Is this application a personal or corporate request? <span className="text-danger">*</span></h5>
                                            <div className="form-group">
                                                <select className="form-control"
                                                        value={editedItem.corporate_request}
                                                        onChange={(e) => handleInputChange("corporate_request", e.target.value)}>
                                                    <option value="" disabled>Please Choose Profile Type</option>
                                                    <option value="personal">Personal</option>
                                                    <option value="corporate">Corporate</option>
                                                </select>
                                                {errors.corporate_request && (
                                                    <div className="tool_tip text-danger">{errors.corporate_request}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="faq-box d-flex mb-4">
                                        <div className="flex-shrink-0 me-3 faq-icon">
                                            <i className="bx bx-help-circle font-size-20 text-success"></i>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="font-size-15">What is your main interest? <span className="text-danger">*</span></h5>
                                            <select className="form-control"
                                                    value={editedItem.main_interest}
                                                    onChange={(e) => handleInputChange("main_interest", e.target.value)}>
                                                <option value="">Please Choose Main Profile Type</option>
                                                <option value="To open 1 store in my region">To open 1 store in my region</option>
                                                <option value="To open few stores in my region">To open few stores in my region</option>
                                                <option value="To expand YOLE across a region/country in exclusivity as the Master-Franchisee">To expand YOLE across a region/country in exclusivity as the Master-Franchisee</option>
                                            </select>
                                            {errors.main_interest && (
                                                <div className="tool_tip text-danger">{errors.main_interest}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="faq-box d-flex mb-4">
                                        <div className="flex-shrink-0 me-3 faq-icon">
                                            <i className="bx bx-help-circle font-size-20 text-success"></i>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="font-size-15">Main Profile of your group? <span className="text-danger">*</span></h5>
                                            <select className="form-control"
                                                    value={editedItem.main_profile_group}
                                                    onChange={(e) => handleInputChange("main_profile_group", e.target.value)}>
                                                <option value="">Please Choose Main Profile Group</option>
                                                <option value="Holding Group">Holding Group</option>
                                                <option value="Franchise Operator">Franchise Operator</option>
                                                <option value="Family Group">Family Group</option>
                                                <option value="Other">Other</option>
                                            </select>
                                            {errors.main_profile_group && (
                                                <div className="tool_tip text-danger">{errors.main_profile_group}</div>
                                            )}
                                        </div>
                                    </div>
                                    {editedItem.main_profile_group === "Other" && (
                                        <div className="faq-box d-flex mb-4">
                                            <div className="flex-shrink-0 me-3 faq-icon">
                                                <i className="bx bx-help-circle font-size-20 text-success"></i>
                                            </div>
                                            <div className="flex-grow-1">
                                                <h5 className="font-size-15">If "Other", please specify <span className="text-danger">*</span></h5>
                                                <textarea
                                                    className="form-control"
                                                    value={editedItem.others || ''}
                                                    onChange={(e) => handleInputChange("others", e.target.value)}></textarea>
                                                {errors.others && (
                                                    <div className="tool_tip text-danger">{errors.others}</div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    <div className="faq-box d-flex mb-4">
                                        <div className="flex-shrink-0 me-3 faq-icon">
                                            <i className="bx bx-help-circle font-size-20 text-success"></i>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="font-size-15">Current Liquidity Asset (Cash) <span className="text-danger">*</span></h5>
                                            <select className="form-control"
                                                    value={editedItem.current_liquidity_asset}
                                                    onChange={(e) => handleInputChange("current_liquidity_asset", e.target.value)}>
                                                <option value="">Please Choose Current Liquidity Asset</option>
                                                <option value="Less than $100k (USD)">Less than $100k (USD)</option>
                                                <option value="$250k - $500K (USD)">$250k - $500K (USD)</option>
                                                <option value="$500k - $1M (USD)">$500k - $1M (USD)</option>
                                                <option value="More than $1M (USD)">More than $1M (USD)</option>
                                            </select>
                                            {errors.current_liquidity_asset && (
                                                <div className="tool_tip text-danger">{errors.current_liquidity_asset}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="faq-box d-flex mb-4">
                                        <div className="flex-shrink-0 me-3 faq-icon">
                                            <i className="bx bx-help-circle font-size-20 text-success"></i>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="font-size-15">What is your capital to invest in the YOLE project? <span className="text-danger">*</span></h5>
                                            <select className="form-control"
                                                    value={editedItem.capital_to_invest}
                                                    onChange={(e) => handleInputChange("capital_to_invest", e.target.value)}>
                                                <option value="">Please Choose Capital Invest</option>
                                                <option value="Less than $100k (USD)">Less than $100k (USD)</option>
                                                <option value="$250k - $500K (USD)">$250k - $500K (USD)</option>
                                                <option value="$500k - $1M (USD)">$500k - $1M (USD)</option>
                                                <option value="More than $1M (USD)">More than $1M (USD)</option>
                                            </select>
                                            {errors.capital_to_invest && (
                                                <div className="tool_tip text-danger">{errors.capital_to_invest}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="faq-box d-flex mb-4">
                                        <div className="flex-shrink-0 me-3 faq-icon">
                                            <i className="bx bx-help-circle font-size-20 text-success"></i>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="font-size-15">Do you or your company have experience in retail? <span className="text-danger">*</span></h5>
                                            <select className="form-control"
                                                    value={editedItem.experience_in_retail}
                                                    onChange={(e) => handleInputChange("experience_in_retail", e.target.value)}>
                                                <option value="">Please Choose Experience In Retail</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                            {errors.experience_in_retail && (
                                                <div className="tool_tip text-danger">{errors.experience_in_retail}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="faq-box d-flex mb-4">
                                        <div className="flex-shrink-0 me-3 faq-icon">
                                            <i className="bx bx-help-circle font-size-20 text-success"></i>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="font-size-15">Do you or your company have experience in F&B sector? <span className="text-danger">*</span></h5>
                                            <select className="form-control"
                                                    value={editedItem.experience_in_fb_sector}
                                                    onChange={(e) => handleInputChange("experience_in_fb_sector", e.target.value)}>
                                                <option value="">Please Choose F&amp;B Experience In Retail</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                            {errors.experience_in_fb_sector && (
                                                <div className="tool_tip text-danger">{errors.experience_in_fb_sector}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="faq-box d-flex mb-4">
                                        <div className="flex-shrink-0 me-3 faq-icon">
                                            <i className="bx bx-help-circle font-size-20 text-success"></i>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="font-size-15">Do you or your company have experience in franchises? <span className="text-danger">*</span></h5>
                                            <select className="form-control"
                                                    value={editedItem.company_experience_in_franchisee}
                                                    onChange={(e) => handleInputChange("company_experience_in_franchisee", e.target.value)}>
                                                <option value="">Please Choose Franchise Experience</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                            {errors.company_experience_in_franchisee && (
                                                <div className="tool_tip text-danger">{errors.company_experience_in_franchisee}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="faq-box d-flex mb-4">
                                        <div className="flex-shrink-0 me-3 faq-icon">
                                            <i className="bx bx-help-circle font-size-20 text-success"></i>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="font-size-15">How many units would you plan to expand in the next 5 years? <span className="text-danger">*</span></h5>
                                            <select className="form-control"
                                                    value={editedItem.plan_to_extend}
                                                    onChange={(e) => handleInputChange("plan_to_extend", e.target.value)}>
                                                <option value="">Please Choose Units To Open</option>
                                                <option value="From 1 to 6 units">From 1 to 6 units</option>
                                                <option value="From 6 to 10 units">From 6 to 10 units</option>
                                                <option value="From 11 to 20 units">From 11 to 20 units</option>
                                                <option value="From 21 to 30 units">From 21 to 30 units</option>
                                                <option value="From 31 to 50 units">From 31 to 50 units</option>
                                                <option value="From 51 to 100 units">From 51 to 100 units</option>
                                                <option value="More than 100 units">More than 100 units</option>
                                            </select>
                                            {errors.plan_to_extend && (
                                                <div className="tool_tip text-danger">{errors.plan_to_extend}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="faq-box d-flex mb-4">
                                        <div className="flex-shrink-0 me-3 faq-icon">
                                            <i className="bx bx-help-circle font-size-20 text-success"></i>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="font-size-15">If you apply as a group/company, please describe your current business</h5>
                                            <textarea
                                                className="form-control"
                                                value={editedItem.describe_your_current_business || ''}
                                                onChange={(e) => handleInputChange("describe_your_current_business", e.target.value)}></textarea>
                                        </div>
                                    </div>
                                    <div className="faq-box d-flex mb-4">
                                        <div className="flex-shrink-0 me-3 faq-icon">
                                            <i className="bx bx-help-circle font-size-20 text-success"></i>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="font-size-15">If you apply as Masterfranchise, we are interested to hear about your expansion plan</h5>
                                            <textarea
                                                className="form-control"
                                                value={editedItem.your_expansion_plan || ''}
                                                onChange={(e) => handleInputChange("your_expansion_plan", e.target.value)}></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-12 mt-2 mb-4 text-center">
                        <button type="submit" className="btn btn-success">
                            Save Changes
                        </button>
                    </div>
                </div>
            </form>
            {loading && <Loader />}
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />
        </div> 
    );
}

export default FranchiseeEdit;