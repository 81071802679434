import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import axios from "../../axios";
import Loader from "../../components/Loader";
import {toast} from "react-toastify";

function DistributorEdit() {
    const { id} = useParams();
    const [item, setItem] = useState(null);
    const [editedItem, setEditedItem] = useState(null);
    const [errors, setErrors] = useState({});
    const [countries, setCountries] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`get_distributor/${id}`);
                // console.log('Response', response.data.data[0]);
                setItem(response.data.data);
                // setEditedItem(response.data.data);
                setEditedItem((prevItem) => ({
                    ...prevItem,
                    ...response.data.data,
                    distribution_channels: JSON.parse(response.data.data.distribution_channels || "[]"),
                    annual_volume: JSON.parse(response.data.data.annual_volume || "[]"),
                }));

                const countriesList = await axios.get('/country_list');
                setCountries(countriesList.data.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [id]);

    const handleInputChange = (fieldName, value) => {
        setEditedItem((prevItem) => ({
            ...prevItem,
            [fieldName]: value,
        }));

        setErrors((prevErrors) => ({
            ...prevErrors,
            [fieldName]: ""
        }));
    }

    const validateForm = () => {
        const errors = {};

        if (!editedItem.company) {
            errors.company = 'This field is required';
        }

        if (!editedItem.website) {
            errors.website = 'This field is required';
        }

        if (!editedItem.email) {
            errors.email = 'This field is required';
        } else if (!/^\S+@\S+\.\S+$/.test(editedItem.email)) {
            errors.email = 'Invalid email format';
        }

        if (!editedItem.country) {
            errors.country = 'This field is required';
        }

        if (!editedItem.additional_contact_email) {
            errors.additional_contact_email = 'This field is required';
        }

        if (!editedItem.your_position) {
            errors.your_position = 'This field is required';
        }

        if (!editedItem.are_you_a) {
            errors.are_you_a = 'This field is required';
        }

        if (!editedItem.import_frozen_goods) {
            errors.import_frozen_goods = 'This field is required';
        }

        if (!editedItem.distribute_frozen_yogurt) {
            errors.distribute_frozen_yogurt = 'This field is required';
        }

        if (editedItem.distribution_channels.length === 0) {
            errors.distribution_channels = 'Please select at least one option';
        }

        if (editedItem.annual_volume.length === 0) {
            errors.annual_volume = 'Please select at least one option';
        }

        if (!editedItem.others) {
            errors.others = 'This field is required';
        }

        if (!editedItem.import_per_year) {
            errors.import_per_year = 'This field is required';
        }

        if (!editedItem.annual_gross_profit) {
            errors.annual_gross_profit = 'This field is required';
        }

        if (!editedItem.number_of_employees) {
            errors.number_of_employees = 'This field is required';
        }

        if (!editedItem.company_businesses_other_sector) {
            errors.company_businesses_other_sector = 'This field is required';
        }

        if (!editedItem.yes_specify) {
            errors.yes_specify = 'This field is required';
        }

        if (!editedItem.capacity_to_distribute) {
            errors.capacity_to_distribute = 'This field is required';
        }

        setErrors(errors);
        return Object.keys(errors).length === 0; // Form is valid if there are no errors
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submission

        try {
            if (validateForm()) {
                setLoading(true);
                // You can make an API call here to update the data
                await axios.post(`update_distributor/${id}`, editedItem);
                // Optionally, you can fetch the updated data again and update the state
                const response = await axios.get(`get_distributor/${id}`);
                setItem(response.data.data);

                toast.success(response.data.message);
                navigate(`/distributor/${id}`);
            }
        } catch (error) {
            console.error('Error updating data:', error);
        } finally {
            setLoading(false);
        }
    };

    if (!item) {
        return <Loader />;
    }

    return(
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">Profile</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><Link to="/distributor">Distributor</Link></li>
                                <li className="breadcrumb-item active"><Link to={`/distributor/${id}`}>{item.company}</Link></li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <form onSubmit={handleFormSubmit} className="display-edit">
                <div className="row">
                    <div className="col-xl-4">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title mb-4">Company Information</h4>
                                <div className="table-responsive">
                                    <table className="table table-nowrap mb-0">
                                        <tbody>
                                        <tr>
                                            <th scope="row">Company :</th>
                                            <td>
                                                <div className="form-group">
                                                    <input type="text"
                                                           className="form-control"
                                                           value={editedItem.company}
                                                           onChange={(e) => handleInputChange("company", e.target.value)}
                                                    />
                                                    {errors.company && (
                                                        <div className="tool_tip text-danger">{errors.company}</div>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Website :</th>
                                            <td>
                                                <div className="form-group">
                                                    <input type="text"
                                                           className="form-control"
                                                           value={editedItem.website}
                                                           onChange={(e) => handleInputChange("website", e.target.value)}
                                                    />
                                                    {errors.website && (
                                                        <div className="tool_tip text-danger">{errors.website}</div>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">E-mail :</th>
                                            <td>
                                                <div className="form-group">
                                                    <input type="email"
                                                           className="form-control"
                                                           value={editedItem.email}
                                                           onChange={(e) => handleInputChange("email", e.target.value)}
                                                    />
                                                    {errors.email && (
                                                        <div className="tool_tip text-danger">{errors.email}</div>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Country :</th>
                                            <td>
                                                <div className="form-group">
                                                    <select
                                                        className="form-control"
                                                        value={editedItem.country}
                                                        onChange={(e) => handleInputChange("country", e.target.value)}
                                                    >
                                                        <option value="" disabled>Select Country</option>
                                                        {countries.map((country) => (
                                                            <option key={country} value={country}>
                                                                {country}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {errors.country && (
                                                        <div className="tool_tip text-danger">{errors.country}</div>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-8">
                        <div className="card">
                            <div className="card-body">
                                <div className="tab-pane fade active show" id="v-pills-gen-ques" role="tabpanel" aria-labelledby="v-pills-gen-ques-tab">
                                    <h4 className="card-title mb-5">Distributor Information</h4>
                                    <div className="faq-box d-flex mb-2">
                                        <div className="flex-shrink-0 me-3 faq-icon">
                                            <i className="bx bx-help-circle font-size-20 text-success"></i>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="font-size-15">Additional contact email</h5>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={editedItem.additional_contact_email}
                                                    onChange={(e) => handleInputChange("additional_contact_email", e.target.value)}
                                                />
                                                {errors.additional_contact_email && (
                                                    <div className="tool_tip text-danger">{errors.additional_contact_email}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="faq-box d-flex mb-2">
                                        <div className="flex-shrink-0 me-3 faq-icon">
                                            <i className="bx bx-help-circle font-size-20 text-success"></i>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="font-size-15">Your position in the company</h5>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={editedItem.your_position}
                                                    onChange={(e) => handleInputChange("your_position", e.target.value)}
                                                />
                                                {errors.your_position && (
                                                    <div className="tool_tip text-danger">{errors.your_position}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="faq-box d-flex mb-2">
                                        <div className="flex-shrink-0 me-3 faq-icon">
                                            <i className="bx bx-help-circle font-size-20 text-success"></i>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="font-size-15">Are you a</h5>
                                            <div className="form-group">
                                                <select className="form-control"
                                                        value={editedItem.are_you_a}
                                                        onChange={(e) => handleInputChange("are_you_a", e.target.value)}>
                                                    <option value="">Please Choose Option</option>
                                                    <option value="Importer/distributor, and I have my own infrastructure for cold logistic">Importer/distributor, and I have my own infrastructure for cold logistic</option>
                                                    <option value="Importer/distributor, but I don't have my own infrastructure (cold logistic)">Importer/distributor, but I don't have my own infrastructure (cold logistic)</option>
                                                    <option value="Direct Purchaser (Supermarket Chain, convenience store…)">Direct Purchaser (Supermarket Chain, convenience store…)</option>
                                                    <option value="Agent or third party (No a direct purchaser/distributor)">Agent or third party (No a direct purchaser/distributor)</option>
                                                </select>
                                                {errors.are_you_a && (
                                                    <div className="tool_tip text-danger">{errors.are_you_a}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="faq-box d-flex mb-2">
                                        <div className="flex-shrink-0 me-3 faq-icon">
                                            <i className="bx bx-help-circle font-size-20 text-success"></i>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="font-size-15">Does your company currently import frozen goods?</h5>
                                            <div className="form-group">
                                                <select className="form-control"
                                                        value={editedItem.import_frozen_goods}
                                                        onChange={(e) => handleInputChange("import_frozen_goods", e.target.value)}>
                                                    <option value="">Please Choose Option</option>
                                                    <option value="yes">Yes</option>
                                                    <option value="no">No</option>
                                                </select>
                                                {errors.import_frozen_goods && (
                                                    <div className="tool_tip text-danger">{errors.import_frozen_goods}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="faq-box d-flex mb-2">
                                        <div className="flex-shrink-0 me-3 faq-icon">
                                            <i className="bx bx-help-circle font-size-20 text-success"></i>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="font-size-15">Does your company currently distribute any ice cream or frozen yogurt brand?</h5>
                                            <div className="form-group">
                                                <select className="form-control"
                                                        value={editedItem.distribute_frozen_yogurt}
                                                        onChange={(e) => handleInputChange("distribute_frozen_yogurt", e.target.value)}>
                                                    <option value="">Please Choose Profile Type</option>
                                                    <option value="yes">Yes</option>
                                                    <option value="no">No</option>
                                                </select>
                                                {errors.distribute_frozen_yogurt && (
                                                    <div className="tool_tip text-danger">{errors.distribute_frozen_yogurt}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="faq-box d-flex mb-2">
                                        <div className="flex-shrink-0 me-3 faq-icon">
                                            <i className="bx bx-help-circle font-size-20 text-success"></i>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="font-size-15">What are your main distribution channels?</h5>
                                            <div className="form-group">
                                                <select className="form-control"
                                                        value={editedItem.distribution_channels || []}
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                "distribution_channels",
                                                                Array.from(e.target.selectedOptions, (option) => option.value)
                                                            )
                                                        }
                                                        multiple={true}
                                                >
                                                    <option value="Horeca">Horeca</option>
                                                    <option value="Small Retailers">Small Retailers</option>
                                                    <option value="Supermarkets & Convenience Stores">Supermarkets & Convenience Stores</option>
                                                    <option value="Online">Online</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                                {errors.distribution_channels && (
                                                    <div className="tool_tip text-danger">{errors.distribution_channels}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="faq-box d-flex mb-2">
                                        <div className="flex-shrink-0 me-3 faq-icon">
                                            <i className="bx bx-help-circle font-size-20 text-success"></i>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="font-size-15">Which channel/s represent at least the 70% of your annual volume?</h5>
                                            <div className="form-group">
                                                <select className="form-control"
                                                        value={editedItem.annual_volume || []}
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                "annual_volume",
                                                                Array.from(e.target.selectedOptions, (option) => option.value)
                                                            )
                                                        }
                                                        multiple={true}
                                                >
                                                    <option value="Horeca">Horeca</option>
                                                    <option value="Small Retailers">Small Retailers</option>
                                                    <option value="Supermarkets & Convenience Stores">Supermarkets & Convenience Stores</option>
                                                    <option value="Online">Online</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                                {errors.annual_volume && (
                                                    <div className="tool_tip text-danger">{errors.annual_volume}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="faq-box d-flex mb-2">
                                        <div className="flex-shrink-0 me-3 faq-icon">
                                            <i className="bx bx-help-circle font-size-20 text-success"></i>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="font-size-15">If "OTHER", please specify channel</h5>
                                            <textarea
                                                className="form-control"
                                                value={editedItem.others || ''}
                                                onChange={(e) => handleInputChange("others", e.target.value)}></textarea>
                                            {errors.others && (
                                                <div className="tool_tip text-danger">{errors.others}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="faq-box d-flex mb-2">
                                        <div className="flex-shrink-0 me-3 faq-icon">
                                            <i className="bx bx-help-circle font-size-20 text-success"></i>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="font-size-15">How many containers (40sqft) do you usually import per year?</h5>
                                            <div className="form-group">
                                                <select className="form-control"
                                                        value={editedItem.import_per_year}
                                                        onChange={(e) => handleInputChange("import_per_year", e.target.value)}>
                                                    <option value="">Please Choose Option</option>
                                                    <option value="From 6 to 10 containers">From 6 to 10 containers</option>
                                                    <option value="From 11 to 20 containers">From 11 to 20 containers</option>
                                                    <option value="From 21 to 30 containers">From 21 to 30 containers</option>
                                                    <option value="From 31 to 50 containers">From 31 to 50 containers</option>
                                                    <option value="From 51 to 100 containers">From 51 to 100 containers</option>
                                                    <option value="More than 100 containers">More than 100 containers</option>
                                                </select>
                                                {errors.import_per_year && (
                                                    <div className="tool_tip text-danger">{errors.import_per_year}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="faq-box d-flex mb-2">
                                        <div className="flex-shrink-0 me-3 faq-icon">
                                            <i className="bx bx-help-circle font-size-20 text-success"></i>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="font-size-15">What is your average Annual Gross Profit?</h5>
                                            <div className="form-group">
                                                <select className="form-control"
                                                        value={editedItem.annual_gross_profit}
                                                        onChange={(e) => handleInputChange("annual_gross_profit", e.target.value)}>
                                                    <option value="">Please Choose Option</option>
                                                    <option value="From 6 to 10 containers">From 6 to 10 containers</option>
                                                    <option value="From 11 to 20 containers">From 11 to 20 containers</option>
                                                    <option value="From 21 to 30 containers">From 21 to 30 containers</option>
                                                    <option value="From 31 to 50 containers">From 31 to 50 containers</option>
                                                    <option value="From 51 to 100 containers">From 51 to 100 containers</option>
                                                    <option value="More than 100 containers">More than 100 containers</option>
                                                </select>
                                                {errors.annual_gross_profit && (
                                                    <div className="tool_tip text-danger">{errors.annual_gross_profit}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="faq-box d-flex mb-2">
                                        <div className="flex-shrink-0 me-3 faq-icon">
                                            <i className="bx bx-help-circle font-size-20 text-success"></i>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="font-size-15">Number of employees</h5>
                                            <div className="form-group">
                                                <select className="form-control"
                                                        value={editedItem.number_of_employees}
                                                        onChange={(e) => handleInputChange("number_of_employees", e.target.value)}>
                                                    <option value="">Please Choose Option</option>
                                                    <option value="Less than 10 employees">Less than 10 employees</option>
                                                    <option value="From 11 to 20 employees">From 11 to 20 employees</option>
                                                    <option value="From 20 to 50 employees">From 20 to 50 employees</option>
                                                    <option value="From 50 to 100 employees">From 50 to 100 employees</option>
                                                    <option value="From 100 to 1,000 employees">From 100 to 1,000 employees</option>
                                                    <option value="More than 1,000 employees">More than 1,000 employees</option>
                                                </select>
                                                {errors.number_of_employees && (
                                                    <div className="tool_tip text-danger">{errors.number_of_employees}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="faq-box d-flex mb-2">
                                        <div className="flex-shrink-0 me-3 faq-icon">
                                            <i className="bx bx-help-circle font-size-20 text-success"></i>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="font-size-15">Does your company have other businesses in any other sector?</h5>
                                            <div className="form-group">
                                                <select className="form-control"
                                                        value={editedItem.company_businesses_other_sector}
                                                        onChange={(e) => handleInputChange("company_businesses_other_sector", e.target.value)}>
                                                    <option value="">Please Choose Option</option>
                                                    <option value="yes">Yes</option>
                                                    <option value="no">No</option>
                                                </select>
                                                {errors.company_businesses_other_sector && (
                                                    <div className="tool_tip text-danger">{errors.company_businesses_other_sector}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="faq-box d-flex mb-2">
                                        <div className="flex-shrink-0 me-3 faq-icon">
                                            <i className="bx bx-help-circle font-size-20 text-success"></i>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="font-size-15">If "Yes", please specify</h5>
                                            <textarea
                                                className="form-control"
                                                value={editedItem.yes_specify || ''}
                                                onChange={(e) => handleInputChange("yes_specify", e.target.value)}></textarea>
                                            {errors.yes_specify && (
                                                <div className="tool_tip text-danger">{errors.yes_specify}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="faq-box d-flex mb-2">
                                        <div className="flex-shrink-0 me-3 faq-icon">
                                            <i className="bx bx-help-circle font-size-20 text-success"></i>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="font-size-15">Does your company have the capacity to distribute YOLE products in...?</h5>
                                            <div className="form-group">
                                                <select className="form-control"
                                                        value={editedItem.capacity_to_distribute}
                                                        onChange={(e) => handleInputChange("capacity_to_distribute", e.target.value)}>
                                                    <option value="">Please Choose Option</option>
                                                    <option value="Only some regions inside the country requested">Only some regions inside the country requested</option>
                                                    <option value="Only some regions inside the country requested">Only some regions inside the country requested</option>
                                                    <option value="Across the country requested">Across the country requested</option>
                                                    <option value="Several Countries">Several Countries</option>
                                                    <option value="Globally">Globally</option>
                                                </select>
                                                {errors.capacity_to_distribute && (
                                                    <div className="tool_tip text-danger">{errors.capacity_to_distribute}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="faq-box d-flex mb-2">
                                        <div className="flex-shrink-0 me-3 faq-icon">
                                            <i className="bx bx-help-circle font-size-20 text-success"></i>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="font-size-15">Are you interested in exclusivity rights?</h5>
                                            <div className="form-group">
                                                <select className="form-control"
                                                        value={editedItem.interested_in_exclusivity_rights}
                                                        onChange={(e) => handleInputChange("interested_in_exclusivity_rights", e.target.value)}>
                                                    <option value="">Please Choose Option</option>
                                                    <option value="yes">Yes</option>
                                                    <option value="no">No</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="faq-box d-flex mb-2">
                                        <div className="flex-shrink-0 me-3 faq-icon">
                                            <i className="bx bx-help-circle font-size-20 text-success"></i>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="font-size-15">Please describe your current business</h5>
                                            <textarea
                                                className="form-control"
                                                value={editedItem.describe_your_business || ''}
                                                onChange={(e) => handleInputChange("describe_your_business", e.target.value)}></textarea>
                                        </div>
                                    </div>
                                    <div className="faq-box d-flex mb-2">
                                        <div className="flex-shrink-0 me-3 faq-icon">
                                            <i className="bx bx-help-circle font-size-20 text-success"></i>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="font-size-15">Please, share any additional comments. As a potential YOLE Distributor, we are interested to hear about your expansion/distribution plans</h5>
                                            <textarea
                                                className="form-control"
                                                value={editedItem.share_additional_comments || ''}
                                                onChange={(e) => handleInputChange("share_additional_comments", e.target.value)}></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12 mt-2 mb-4 text-center">
                        <button type="submit" className="btn btn-success">
                            Save Changes
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default DistributorEdit;