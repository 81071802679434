import React, {useEffect, useRef, useState} from "react";
import {Link, useParams} from "react-router-dom";
import axios from "../../axios";
import Loader from "../../components/Loader";
import generateFranchiseePDF from 'react-to-pdf';

function FranchiseeSpainDetail() {
    const { id} = useParams();
    const [item, setItem] = useState(null);
    const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
    const targetRef = useRef();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`get_franchisee_spain/${id}`);
                console.log('Response', response.data.data);
                setItem(response.data.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [id]);

    if (!item) {
        return <Loader />;
    }

    const options = {
        paperSize: {
            width: '210mm', // Adjust the width as needed
            height: '297mm', // Adjust the height as needed
        },
    };

    const fileName = `Spain_Franchisee_${item.first_name}_${item.last_name}.pdf`;

    const handleGeneratePDF = async () => {
        setIsGeneratingPDF(true);

        try {
            await generateFranchiseePDF(targetRef, { filename: fileName });
        } catch (error) {
            console.error('Error generating PDF:', error);
        } finally {
            setIsGeneratingPDF(false);
        }
    };

    return(
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">Profile</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><Link to="/franchisee">Franchisee</Link></li>
                                <li className="breadcrumb-item active">{item.first_name}</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <div className="edit-info float-end mb-3">
                        {/*<Link to={`/franchisee/edit/${id}`} className="btn btn-sm btn-outline-primary" style={{marginRight: '5px'}}>Edit</Link>*/}
                        <button className="btn btn-sm btn-outline-info" onClick={handleGeneratePDF}>
                            {isGeneratingPDF ? 'Generating PDF...' : 'Download PDF'}
                        </button>
                    </div>
                </div>
            </div>

            <div className="row" ref={targetRef} style={{backgroundColor: '#2cd5c4'}}>
                <div className="col-xl-12 p-0">
                    <img src="/assets/images/franchise-form-design-top.png" className="img-fluid"  alt="Franchise Form"/>
                </div>
                <div className="col-xl-12 form-layout p-0">
                    <div className="card">
                        <h4 className="card-title mb-4">Personal Information :</h4>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-striped mb-0">
                                    <tbody>
                                    <tr>
                                        <th scope="row">Nombre *</th>
                                        <td>{item.first_name}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Apellidos *</th>
                                        <td>{item.surnames}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Teléfono *</th>
                                        <td>{item.phone_no}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">E-mail *</th>
                                        <td>{item.email}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Nombre de empresa y website (en caso de ser empresa)</th>
                                        <td>{item.company_name}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Posición en la empresa (dueño, director…)</th>
                                        <td>{item.position_in_company}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <h4 className="card-title mt-2 mb-2">Company Information :</h4>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-striped mb-0">
                                    <tbody>
                                    <tr>
                                        <th scope="row">Cuántas unidades Yolé te gustaría abrir? *</th>
                                        <td>{item.yole_units}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Comunidad Autónoma de tu interés*</th>
                                        <td>{item.community_of_interest}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Interesado en una sola localidad o en varias localidades? *</th>
                                        <td>{item.interested_in_location}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Localidad o localidades de tu interés</th>
                                        <td>{item.locations_of_your_interest}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">En caso de ser empresa, a qué se dedica la empresa</th>
                                        <td>{item.does_company_do}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Cuál es el capital disponible para invertir en una tienda Yolé?*</th>
                                        <td>{item.capital_available}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Tienes tú o tu empresa experiencia previa en ventas al público?*</th>
                                        <td>{item.public_sales_experience}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Tienes tú o tu empresa experiencia previa en restauración?*</th>
                                        <td>{item.restoration_experience}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Tienes tú o tu empresa experiencia previa en franquicias?*</th>
                                        <td>{item.franchising_experience}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Describe a qué te dedicas actualmente o a qué se dedica la empresa. *</th>
                                        <td>{item.describe_your}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Tienes local ya disponible?*</th>
                                        <td>{item.location_already_available}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Estás interesado en Yolé o en el concepto Yolé Café?*</th>
                                        <td>{item.yole_cafe_concept}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Algo más que quieras contarnos para la evaluación de tu candidatura? *</th>
                                        <td>{item.evaluation}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    );
}

export default FranchiseeSpainDetail;