import React, {useEffect, useRef, useState} from "react";
import {Link, useParams} from "react-router-dom";
import axios from "../../axios";
import Loader from "../../components/Loader";
import generateFranchiseePDF from 'react-to-pdf';

function FranchiseeUkDetail() {
    const { id} = useParams();
    const [item, setItem] = useState(null);
    const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
    const targetRef = useRef();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`get_franchisee_uk/${id}`);
                console.log('Response', response.data.data);
                setItem(response.data.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [id]);

    if (!item) {
        return <Loader />;
    }

    const options = {
        paperSize: {
            width: '210mm', // Adjust the width as needed
            height: '297mm', // Adjust the height as needed
        },
    };

    const fileName = `Uk_Franchisee_${item.name}.pdf`;

    const handleGeneratePDF = async () => {
        setIsGeneratingPDF(true);

        try {
            await generateFranchiseePDF(targetRef, { filename: fileName });
        } catch (error) {
            console.error('Error generating PDF:', error);
        } finally {
            setIsGeneratingPDF(false);
        }
    };

    return(
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">Profile</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><Link to="/franchisee">Franchisee</Link></li>
                                <li className="breadcrumb-item active">{item.name}</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <div className="edit-info float-end mb-3">
                        {/*<Link to={`/franchisee/edit/${id}`} className="btn btn-sm btn-outline-primary" style={{marginRight: '5px'}}>Edit</Link>*/}
                        <button className="btn btn-sm btn-outline-info" onClick={handleGeneratePDF}>
                            {isGeneratingPDF ? 'Generating PDF...' : 'Download PDF'}
                        </button>
                    </div>
                </div>
            </div>

            <div className="row" ref={targetRef} style={{backgroundColor: '#2cd5c4'}}>
                <div className="col-xl-12 p-0">
                    <img src="/assets/images/franchise-form-design-top.png" className="img-fluid"  alt="Franchise Form"/>
                </div>
                <div className="col-xl-12 form-layout p-0">
                    <div className="card">
                        <h4 className="card-title mb-4">Personal Information :</h4>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-striped mb-0">
                                    <tbody>
                                    <tr>
                                        <th scope="row">Name *</th>
                                        <td>{item.name}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Surname *</th>
                                        <td>{item.surnames}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Phone *</th>
                                        <td>{item.phone_no}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">E-mail *</th>
                                        <td>{item.email}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Company name and website (if it is a company)</th>
                                        <td>{item.company_name}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Position in the company (owner, director…)</th>
                                        <td>{item.position_in_company}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <h4 className="card-title mt-2 mb-2">Company Information :</h4>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-striped mb-0">
                                    <tbody>
                                    <tr>
                                        <th scope="row">How many Yolé units would you like to open? *</th>
                                        <td>{item.yole_units}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Region of your interest*</th>
                                        <td>{item.region_of_interest}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Interested in a single location or multiple locations? *</th>
                                        <td>{item.interested_in_location}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Location or locations of your interest</th>
                                        <td>{item.locations_of_your_interest}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">If it is a company, what does the company do</th>
                                        <td>{item.does_company_do}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">What is the capital available to invest in a Yolé store?*</th>
                                        <td>{item.capital_available}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Do you or your company have previous experience in retail?*</th>
                                        <td>{item.experience_in_retail}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Do you or your company have previous experience in F&B?*</th>
                                        <td>{item.experience_in_fb}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Do you or your company have previous experience in franchising?*</th>
                                        <td>{item.previous_experience_in_franchisee}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Describe what you currently do or what the company does *</th>
                                        <td>{item.what_the_company_does}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Do you have a property already available?*</th>
                                        <td>{item.location_already_available}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Are you interested in Yolé or the Yolé Café concept?*</th>
                                        <td>{item.yole_cafe_concept}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Is there any other supporting information you would like to provide? *</th>
                                        <td>{item.other_supporting_information}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    );
}

export default FranchiseeUkDetail;