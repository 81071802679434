import React, {useEffect, useRef, useState} from "react";
import {Link, useParams} from "react-router-dom";
import axios from "../../axios";
import Loader from "../../components/Loader";
import generateDistributorPDF from "react-to-pdf";
import generateFranchiseePDF from "react-to-pdf";

function DistributorDetail() {
    const { id} = useParams();
    const [item, setItem] = useState(null);
    const [distributionChannel, setDistributionChannel] = useState([]);
    const [annualVolume, setAnnualVolume] = useState([]);
    const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
    const targetRef = useRef();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`get_distributor/${id}`);
                console.log('Response', response.data.data);
                setItem(response.data.data);
                setDistributionChannel(JSON.parse(response.data.data.distribution_channels));
                setAnnualVolume(JSON.parse(response.data.data.annual_volume));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [id]);

    // console.log('distributionChannel', distributionChannel);

    if (!item) {
        return <Loader />;
    }

    const fileName = `Distributor_${item.company}.pdf`;

    const handleGeneratePDF = async () => {
        setIsGeneratingPDF(true);

        try {
            await generateFranchiseePDF(targetRef, { filename: fileName });
        } catch (error) {
            console.error('Error generating PDF:', error);
        } finally {
            setIsGeneratingPDF(false);
        }
    };

    return(
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">Profile</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><Link to="/franchisee">Distributor</Link></li>
                                <li className="breadcrumb-item active">{item.company}</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <div className="edit-info float-end mb-3">
                        <Link to={`/distributor/edit/${id}`} className="btn btn-sm btn-outline-primary" style={{marginRight: '5px'}}>Edit</Link>
                        <button className="btn btn-sm btn-outline-info" onClick={handleGeneratePDF}>
                            {isGeneratingPDF ? 'Generating PDF...' : 'Download PDF'}
                        </button>
                    </div>
                </div>
            </div>

            <div className="row" ref={targetRef}>
                <div className="col-xl-12 p-0">
                    <img src="/assets/images/distributor-form-design-top.png" className="img-fluid"  alt="Franchise Form"/>
                </div>
                <div className="col-xl-12 form-layout p-0">
                    <div className="card">
                        <h4 className="card-title mb-4">Personal Information</h4>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-striped mb-0">
                                    <tbody>
                                    <tr>
                                        <th scope="row">Company *</th>
                                        <td>{item.company}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Website *</th>
                                        <td>
                                            <Link to={item.website} target={"_blank"}>{item.website}</Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">E-mail *</th>
                                        <td>{item.email}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Country *</th>
                                        <td>{item.country}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Additional contact email</th>
                                        <td>{item.additional_contact_email}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Your position in the company</th>
                                        <td>{item.your_position}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <h4 className="card-title mt-2 mb-2">Company Information :</h4>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-striped mb-0">
                                    <tbody>
                                    <tr>
                                        <th scope="row">Are you a *</th>
                                        <td>{item.are_you_a}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Does your company currently import frozen goods?</th>
                                        <td>{item.import_frozen_goods}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Does your company currently distribute any ice cream or frozen yogurt brand?</th>
                                        <td>{item.distribute_frozen_yogurt}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">What are your main distribution channels?</th>
                                        <td>
                                            <ul className="d-inline-block mb-0" style={{paddingLeft: '15px'}}>
                                                {distributionChannel.map((channel, index) => (
                                                    <li key={index}>{channel}</li>
                                                ))}
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Which channel/s represent at least the 70% of your annual volume?</th>
                                        <td>
                                            <ul className="d-inline-block mb-0" style={{paddingLeft: '15px'}}>
                                                {annualVolume.map((channel, index) => (
                                                    <li key={index}>{channel}</li>
                                                ))}
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">If "OTHER", please specify channel</th>
                                        <td>{item.others}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">How many containers (40sqft) do you usually import per year?</th>
                                        <td>{item.import_per_year}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">What is your average Annual Gross Profit?</th>
                                        <td>{item.annual_gross_profit}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Number of employees</th>
                                        <td>{item.number_of_employees}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Does your company have other businesses in any other sector?</th>
                                        <td>{item.company_businesses_other_sector}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">If "Yes", please specify</th>
                                        <td>{item.yes_specify}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Does your company have the capacity to distribute YOLE products in...?</th>
                                        <td>{item.capacity_to_distribute}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Are you interested in exclusivity rights?</th>
                                        <td>{item.interested_in_exclusivity_rights}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Please describe your current business</th>
                                        <td>{item.describe_your_business}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Please, share any additional comments. As a potential YOLE Distributor, we are interested to hear about your expansion/distribution plans</th>
                                        <td>{item.share_additional_comments}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DistributorDetail;