import React from "react";
import Header from "./Header";
import Nav from "./Nav";
import Footer from "./Footer";

function Layout({ children }){
    return(
        <div id="layout-wrapper">
            <Header />
            <Nav />

            <div className="main-content">
                <div className="page-content">
                    {children}
                </div>

                <Footer />
            </div>
        </div>
    );
}

export default Layout;