import React, { useEffect, useState } from "react";
import axios from "../../axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../components/Loader";
import { Link } from "react-router-dom";
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
// import { exportData } from 'primereact/utils';

function SpainFranchisee() {
    const [franchiseeList, setFranchiseeList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [globalFilter, setGlobalFilter] = useState('');

    useEffect(() => {
        setLoading(true);
        fetchData();
    }, [first, rows, globalFilter]);

    const fetchData = (countryFilter = 'ES') => {
        const currentPage = Math.floor(first / rows) + 1;
        axios.get(`/get_franchisee_spain?page=${currentPage}&pageSize=${rows}&globalFilter=${globalFilter}`)
            .then((response) => {
                setFranchiseeList(response.data.data);
                setTotalRecords(response.data.totalRecords);
            })
            .catch((error) => {
                toast.error('Something went wrong.');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onPage = (event) => {
        const newFirst = event.first;
        setFirst(newFirst);
        setRows(event.rows);
    };

    const indexTemplate = (rowData, column) => {
        return (
            <React.Fragment>
                {first + franchiseeList.indexOf(rowData) + 1}
            </React.Fragment>
        );
    };


    const filteredData = franchiseeList.filter((item) =>
        Object.values(item).some(
            (val) =>
                val &&
                val.toString().toLowerCase().includes(globalFilter.toLowerCase())
        )
    );

    /*const exportPDF = () => {
        // You can customize the PDF export settings here if needed
        const exportColumns = [
            { field: 'first_name', header: 'First Name' },
            { field: 'last_name', header: 'Last Name' },
            { field: 'email', header: 'email' },
            { field: 'Phone No', header: 'phone_no' },
            { field: 'Company Name', header: 'company_name' },
            { field: 'Country Of Interest', header: 'country_of_interest' },
        ];

        const doc = exportData(franchiseeList, { exportFunction: exportPDF, columns: exportColumns });
        doc.save('franchisee-list.pdf');
    };*/

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive">
                                <h4 className="card-title">Franchisee List</h4>
                                <div className="row">
                                    <div className="col-lg-3 offset-lg-9">
                                        <InputText
                                            placeholder="Search..."
                                            className="form-control"
                                            value={globalFilter}
                                            onChange={(e) => setGlobalFilter(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="table-responsive mt-3">
                                    <DataTable
                                        value={filteredData}
                                        loading={loading}
                                        paginator
                                        rows={rows}
                                        totalRecords={totalRecords}
                                        lazy
                                        onPage={onPage}
                                        first={first}
                                        tableStyle={{ minWidth: '85rem' }}
                                    >
                                        <Column header="#" body={indexTemplate} className="text-center"></Column>
                                        <Column field="first_name" header="First Name" className="text-capitalize"></Column>
                                        <Column field="surnames" header="Sur Name" className="text-capitalize"></Column>
                                        <Column field="email" header="Email"></Column>
                                        <Column field="phone_no" header="Phone No"></Column>
                                        <Column field="company_name" header="Company Name" className="text-capitalize"></Column>
                                        <Column field="community_of_interest" header="Country of Interest" className="text-capitalize"></Column>
                                        <Column field="yole_units" header="Yole Units" className="text-capitalize"></Column>
                                        <Column field="formatted_created_at" header="Date" className="text-capitalize"></Column>
                                        <Column header="Action" body={(rowData) => (
                                            <React.Fragment>
                                                <Link to={`/spain-franchisee/${rowData.id}`} className="btn btn-light btn-sm" style={{marginRight: '6px'}}>View</Link>
                                                {/*<Link to={`/franchisee/edit/${rowData.id}`} className="btn btn-outline-primary btn-sm">Edit</Link>*/}
                                            </React.Fragment>
                                        )}></Column>
                                    </DataTable>
                                    {loading && <Loader />}
                                    <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SpainFranchisee;
