import React from "react";

function Register() {
    

    return(
        <div className="account-pages my-5 pt-sm-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6 col-xl-5">
                        <div className="card overflow-hidden">
                            <div className="bg-primary bg-soft">
                                <div className="row">
                                    <div className="col-7">
                                        <div className="text-primary p-4">
                                            <h5 className="text-primary">Free Register</h5>
                                            <p>Get your free Skote account now.</p>
                                        </div>
                                    </div>
                                    <div className="col-5 align-self-end">
                                        <img src="assets/images/profile-img.png" alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body pt-0">
                                <div>
                                    <a href="index.html">
                                        <div className="avatar-md profile-user-wid mb-4">
                                            <span className="avatar-title rounded-circle bg-light">
                                                <img src="assets/images/logo.svg" alt="" className="rounded-circle" height="34" />
                                            </span>
                                        </div>
                                    </a>
                                </div>
                                <div className="p-2">
                                    <form className="needs-validation">
                                        <div className="mb-3">
                                            <label htmlFor="useremail" className="form-label">Email</label>
                                            <input type="email" className="form-control" id="useremail" placeholder="Enter email" required />
                                                <div className="invalid-feedback">
                                                    Please Enter Email
                                                </div>
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="username" className="form-label">Username</label>
                                            <input type="text" className="form-control" id="username" placeholder="Enter username" required />
                                                <div className="invalid-feedback">
                                                    Please Enter Username
                                                </div>
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="userpassword" className="form-label">Password</label>
                                            <input type="password" className="form-control" id="userpassword" placeholder="Enter password" required />
                                                <div className="invalid-feedback">
                                                    Please Enter Password
                                                </div>
                                        </div>
                                        <div className="mt-4 d-grid">
                                            <button className="btn btn-primary waves-effect waves-light" type="submit">Register</button>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                        <div className="mt-5 text-center">
                            <div>
                                <p>© <script>document.write(new Date().getFullYear())</script> Skote. All right reserved.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Register;