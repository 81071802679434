import React, {useState} from "react";
import axios from '../axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../components/Loader";
import {Link, Navigate, useNavigate} from "react-router-dom";


function Login() {

    const replaceStyleAccountPagesClass = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
    };

    const loginFormData = {
        email: '',
        password: '',
    }

    const [formData, setFormData] = useState(loginFormData);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });

        setErrors({
            ...errors,
            [name]: '',
        });
    };

    const validateForm = () => {
        const errors = {};

        if (!formData.email) {
            errors.email = 'This field is required';
        } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
            errors.email = 'Invalid email format';
        }

        if (!formData.password) {
            errors.password = 'This field is required';
        }

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true);
            axios.post('/login', formData)
                .then((response) => {
                    // console.log('Form submitted successfully', response);
                    const token = response.data.success.token;
                    localStorage.setItem('token', token);
                    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

                    // toast.success(response.data.message);
                    // setFormData(loginFormData);
                    // setErrors({});

                    // return <Navigate to="/dashboard" />;
                    navigate('/dashboard');
                })
                .catch((error) => {
                    // console.log('error===', error);
                    if (error.response.status === 401) {
                        toast.error(error.response.data.error);
                    } else {
                        console.error('An error occurred:', error);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };


    return(
        <div className="account-pages" style={replaceStyleAccountPagesClass}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6 col-xl-5">
                        <div className="card overflow-hidden">
                            {/*<div className="bg-primary bg-soft">
                                <div className="row">
                                    <div className="col-7">
                                        <div className="text-primary p-4">
                                            <h5 className="text-primary">Welcome Back !</h5>
                                            <p>Sign in to continue to Yolé.</p>
                                        </div>
                                    </div>
                                    <div className="col-5 align-self-end">
                                        <img src="assets/images/profile-img.png" alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>*/}
                            <div className="card-body">
                                <div className="auth-logo">
                                    <Link to="https://yole.com/" target={"_blank"} className="auth-logo-light">
                                        <div className="mb-4">
                                            <span className="avatar-title pt-2 pb-2" style={{backgroundColor: '#2bd5c3'}}>
                                                <img src="/assets/images/yole-logo.svg" alt="" className="" />
                                            </span>
                                        </div>
                                    </Link>

                                    <Link to="https://yole.com/" target={"_blank"} className="auth-logo-dark">
                                        <div className="mb-4">
                                            <span className="avatar-title pt-2 pb-2" style={{backgroundColor: '#2bd5c3'}}>
                                                <img src="/assets/images/yole-logo.svg" alt="" className="" />
                                            </span>
                                        </div>
                                    </Link>
                                </div>
                                <div className="p-2">
                                    <form className="form-horizontal" onSubmit={handleSubmit}>
                                        <div className="mb-3">
                                            <label htmlFor="username" className="form-label">Email</label>
                                            <input type="email" name="email" className="form-control" id="username"
                                                   value={formData.email}
                                                   onChange={handleChange}
                                                   placeholder="Enter email" autoComplete="off" />
                                            {errors.email && <p className="text-danger error">{errors.email}</p>}
                                        </div>

                                        <div className="mb-3">
                                            <label className="form-label">Password</label>
                                            <div className="input-group auth-pass-inputgroup">
                                                <input type="password" name="password" className="form-control"
                                                       value={formData.password}
                                                       onChange={handleChange}
                                                       placeholder="Enter password" autoComplete="off" />
                                                <button className="btn btn-light " type="button" id="password-addon"><i className="mdi mdi-eye-outline"></i></button>
                                            </div>
                                            {errors.password && <p className="text-danger error">{errors.password}</p>}
                                        </div>

                                        <div className="mt-3 d-grid">
                                            <button className="btn btn-primary waves-effect waves-light" type="submit">Log In</button>
                                        </div>
                                    </form>
                                    {loading && <Loader />}
                                    <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />

                                </div>
                            </div>
                        </div>
                        <div className="mt-5 text-center">
                            <div>
                                <p>© <script>document.write(new Date().getFullYear())</script> Yole. All right reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;